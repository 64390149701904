/* import font at some point */
/* Default CSS */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-weight: 300;
  /* font-family: 'Josefin Sans', sans-serif; */
  font-family: "Jost", sans-serif;

  overflow-x: hidden;
  position: relative;
  background-color: #55a79c !important;
  color: #fff9fb !important;
}
.logo {
  width: 150px;
  padding-top: 3px;
}
.navigation {
  display: flex;
  justify-content: space-evenly;
  background-color: #3e7a72;
  margin: 0;
  align-items: center;
}
#basic-navbar-nav {
  display: flex;
}
.navbar-link {
  display: inline-flex;
  flex-direction: row;
  padding: 20px;
  color: #fff9fb;
  text-decoration: none;
}
.social-icon {
  padding: 10px;
}
.social-logo {
  height: 50px;
  width: 50px;
}
/* .header {
  padding: 10px;
} */
.name {
  color: #fff9fb;
  text-decoration: none;
  font-size: 4vh;
  font-weight: 700;
  /* padding-top: 5px; */
}
a:hover {
  text-shadow: 0px 2px 10px;
  /* box-shadow: 0 5px 15px; */
}
.active {
  color: #0b0500;
}
.navbar-text {
  display: flex;
}
.resume {
  padding-top: 25px;
  /* border: #fff9fb solid;
  border-width: thin;
  padding: 10px 15px; */
  font-weight: bold;
  font-size: large;
}
.banner {
  padding: 30px 150px;
  display: flex;
  gap: 35px;
}
.name-heading {
  font-size: 75px;
}
.brand-statement {
  color: #0b0500;
  opacity: 85%;
  padding-left: 10px;
}
.profile {
  width: 25vw;
  border-radius: 50%;
}
h2 {
  font-size: 30px;
}
.hello,
.about-text {
  color: #0b0500;
}
.hello,
.title {
  padding-left: 10px;
}
.skills {
  display: flex;
  gap: 7vw;
  flex-wrap: wrap;
}
.skill-item img {
  background-color: #fff9fb;
  border-radius: 50%;
}
.skill-text {
  text-align: center;
  font-weight: bold;
  color: #0b0500;
}
.project-list {
  display: flex;
  flex-wrap: wrap;
}
.project-item {
  width: 23vw;
  height: 22vh;
  border: 2px solid #fff9fb;
  border-radius: 10px 10px 0px 0px;
  overflow: hidden;
}
.browser {
  height: 20px;
  background-color: #efeaea;
  display: flex;
  align-items: center;
}
.browser div {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin: 2px;
}
.browser p {
  padding-left: 2vw;
  font-family: monospace;
  color: #0b0500;
}
.circle1 {
  background-color: red;
}
.circle2 {
  background-color: #ffd447;
}
.circle3 {
  background-color: rgb(25, 175, 25);
}
.project-item img {
  width: 100%;
  transition: all 10s ease;
}
.project3 img {
  transition: 30s ease;
}
.project-item img:hover {
  transform: translateY(-100%);
}
#project1,
#project2,
#project3,
#project4,
#project5 {
  width: 35%;
  margin: 30px;
}
.link-block {
  display: flex;
  justify-content: space-around;
}
.project-github {
  color: #ffd447;
  font-weight: 800;
  font-size: large;
  text-decoration: none;
}
.project-description {
  font-weight: 575;
  color: black;
}
.calendar {
padding-left: 150px;  
display: flex;
column-gap: 5vw;
}
.contact {
  text-align: center;
  padding-bottom: 10vh;
}
.email,
.phone {
  border: solid #ffd447;
  border-radius: 10px;
  text-decoration: none;
  color: #ffd447;
  padding: 10px;
  border-width: 1px;
}
.contact-links {
  display: flex;
  justify-content: center;
  gap: 15px;
}
.footer {
  background-color: #3e7a72;
  padding: 3vh;
  /* font-weight: bold; */
  text-align: center;
  position: absolute;
  width: 100%;
}
/* Look more into CSS stuff later lets get bones done */
/* Responsive CSS */
/* this is saying, whenever on any screen and where the screen is less than 620 px and in portrait mode  */
@media (max-width: 620px) and (orientation: portrait) {
  .navigation {
    position: fixed;
    width: 100%;
    font-size: small;
    z-index: 1;
  }
  .logo {
    width: 100px;
  }
  .social-logo {
    height: 30px;
    width: 30px;
  }
  .navbar-text {
    display: block;
    text-align: center;
  }
  .social-icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .me-auto {
    width: 215px;
  }
  #home {
    padding-top: 230px;
    padding-left: 75px;
  }
  .name-heading {
    font-size: 55px;
  }
  .title {
    font-size: 30px;
  }
  .intro-text {
    width: 500px;
  }
  .profile {
    width: 35vw;
  }
  .hello,
  .title {
    padding-left: 5px;
  }
  .banner {
    padding-left: 75px;
  }
  #projects {
    flex-direction: column;
  }
  #project-heading,
  .project-github,
  .contact {
    padding-left: 90px;
  }
  .project-list {
    flex-direction: column;
  }
  .project-block .project-github,
  .project-description {
    text-align: center;
  }
  #project1,
  #project2,
  #project3,
  #project4,
  #project5 {
    width: 240px;
  }
  .project-item {
    width: 250px;
    height: 150px;
  }
  .footer {
    box-sizing: unset;
    /* position: fixed;
    bottom: 0; */
  }
}

@media (max-width: 620px) and (orientation: landscape) {
}
